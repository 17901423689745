import text_styles from 'shared/styles/text_styles.module.scss';
import classNames from 'classnames';
import SwanLayout from 'shared/components/SwanLayout/SwanLayout.js';

import {ReactComponent as GreenCheck} from './green-check.svg';
import styles from './AddManagerAccountMemberSuccessScreen.module.scss';

export default function AddManagerAccountMemberSuccessScreen() {
  const {REACT_APP_MOMO_BANKING_URL, REACT_APP_MOMO_BANKING_LABEL} =
    process.env;
  return (
    <SwanLayout>
      <div className={styles.container}>
        <GreenCheck />
        <p
          className={classNames(
            text_styles.body1_bold_centered,
            styles.header,
          )}>
          Herzlichen Glückwunsch
        </p>
        <div className={styles.text_container}>
          <p className={text_styles.body2_centered}>
            Wir informieren Ihre Hausverwaltung sobald das Konto verwendet
            werden kann.
          </p>

          <br />

          <p className={text_styles.body2_centered}>
            Sie können sich jederzeit in Ihrem Online Banking anmelden unter:
          </p>
        </div>
        <a
          className={classNames(text_styles.body1_bold_centered, styles.link)}
          href={REACT_APP_MOMO_BANKING_URL}>
          {REACT_APP_MOMO_BANKING_LABEL}
        </a>
      </div>
    </SwanLayout>
  );
}
