import {useHistory} from 'react-router-dom';
import classNames from 'classnames';
import text_styles from 'shared/styles/text_styles.module.scss';
import SwanLayout from 'shared/components/SwanLayout/SwanLayout.js';
import {NOT_FOUND} from 'shared/api.js';
import {OWNER_PATHS} from 'shared/constants.js';

import useVerifySwanOnboardingToken from '../../hooks/useVerifySwanOnboardingToken.js';

import {ReactComponent as MomoAccounts} from './momo-konten.svg';
import styles from './ManagerAccountOnboardedScreen.module.scss';

export default function ManagerAccountOnboardedScreen() {
  const history = useHistory();
  const {error, loading} = useVerifySwanOnboardingToken();

  if (error?.code === NOT_FOUND) {
    history.replace(OWNER_PATHS.LinkInvalidScreen);
    return null;
  }

  return (
    <SwanLayout>
      <div className={styles.container}>
        {loading ? (
          <p className={classNames(text_styles.body2_centered, styles.text_1)}>
            Bitte warten Sie einen Moment während wir Ihre Daten laden…
          </p>
        ) : error ? (
          <p className={classNames(text_styles.body2_centered, styles.text_1)}>
            Da ist wohl etwas schief gelaufen. Bitte kontaktieren Sie unseren
            Support.
          </p>
        ) : (
          <>
            <MomoAccounts />
            <p
              className={classNames(
                text_styles.body1_bold_centered,
                styles.header,
              )}>
              Herzlichen Glückwunsch
            </p>
            <div className={styles.text_container}>
              <p className={text_styles.body2_centered}>
                Wir senden Ihnen eine E-Mail sobald das Konto verwendet werden
                kann.
              </p>

              <br />

              <p className={text_styles.body2_centered}>
                Sie können sich bereits jetzt per Klick auf das erstellte Konto
                im Onlinebanking Bereich anmelden:
              </p>
            </div>
            <a
              className={classNames(
                text_styles.body1_bold_centered,
                styles.link,
              )}
              href="https://www.getmomo.app/konten">
              www.getmomo.app/konten
            </a>
          </>
        )}
      </div>
    </SwanLayout>
  );
}
