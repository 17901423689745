import text_styles from 'shared/styles/text_styles.module.scss';
import classNames from 'classnames';
import Button from 'shared/components/Button.js';
import {NOT_FOUND, RPC} from 'shared/api.js';
import {useParams} from 'react-router-dom';
import SwanLayout from 'shared/components/SwanLayout/SwanLayout.js';
import {redirectExternal} from 'shared/utils.js';
import {useState} from 'react';
import momo_service_contract from 'shared/assets/termsheets/2023-07-06.Servicevertrag.Getmomo-Kunde.pdf';
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min.js';
import {OWNER_PATHS} from 'shared/constants.js';

import {handleError} from '../../effects.js';

import {ReactComponent as CheckmarkSheild} from './checkmark-shield.svg';
import styles from './PreOnboardingScreen.module.scss';

export default function PreOnboardingScreen() {
  const {token} = useParams();
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  return (
    <SwanLayout>
      <div className={styles.container}>
        {loading ? (
          <p className={classNames(text_styles.body2_centered, styles.text_1)}>
            Bitte warten Sie einen Moment während wir Ihre Daten laden…
          </p>
        ) : (
          <>
            <CheckmarkSheild />
            <p
              className={classNames(
                text_styles.body1_bold_centered,
                styles.header,
              )}>
              Ihr Konto für alles Rund um das Thema Vermieten
            </p>
            <div className={styles.text_container}>
              <p className={text_styles.body2_bold_centered}>
                Im Folgenden führen wir Sie Schritt für Schritt durch die
                Kontoeröffnung.
              </p>
              <p className={text_styles.body2_bold_centered}>
                Die Einrichtung dauert nur wenige Minuten.
              </p>
              <br />
              <p className={text_styles.body2_centered}>
                Bei Fragen erreichen Sie uns per E-Mail:{' '}
                <a
                  href="mailto:partnersupport@getmomo.de"
                  className={classNames(
                    text_styles.body1_centered,
                    styles.link,
                  )}>
                  partnersupport@getmomo.de
                </a>
              </p>
              <br />
              <p className={text_styles.body2_centered}>
                Per Klick auf „Weiter“ akzeptieren Sie die{' '}
              </p>
              <a
                className={classNames(
                  text_styles.body1_bold_centered,
                  styles.link,
                )}
                href={momo_service_contract}
                target="_blank"
                rel="noreferrer">
                Allgemeinen Geschäftsbedingungen von GetMomo Financial GmbH
              </a>{' '}
              <p className={text_styles.body2_centered}>
                und gelangen zur Kontoeröffnung.
              </p>
            </div>
            <Button
              className={classNames(styles.button_text, styles.button)}
              title="Weiter"
              onClick={() => requestSwanAccount({token, setLoading, history})}
            />
          </>
        )}
      </div>
    </SwanLayout>
  );
}

async function requestSwanAccount({setLoading, token, history}) {
  setLoading(true);
  try {
    const swan_onboarding_url = await RPC('requestSwanAccount', {token});
    redirectExternal(swan_onboarding_url);
  } catch (err) {
    if (err.code === NOT_FOUND) {
      history.replace(OWNER_PATHS.LinkInvalidScreen);
      return;
    }
    handleError(err);
  } finally {
    setLoading(false);
  }
}
