import {useState, useEffect} from 'react';
import {RPC} from 'shared/api.js';
import useSwanSearchParams from 'shared/hooks/useSwanSearchParams.js';

export default function useVerifySwanOnboardingToken() {
  const swan_params = useSwanSearchParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (!swan_params) return;

    setLoading(true);
    setError(false);
    setData(undefined);
    fetchDataUsingToken({
      token: swan_params.state,
      setLoading,
      setData,
      setError,
    });
  }, [swan_params]);

  return {
    loading,
    data,
    error,
    token: swan_params?.state,
    swan_user_auth_code: swan_params?.code,
  };
}

async function fetchDataUsingToken({token, setLoading, setData, setError}) {
  try {
    const result = await RPC('verifySwanOnboardingToken', {token});
    setData({...result});
    setLoading(false);
  } catch {
    setError(true);
    setLoading(false);
  }
}
