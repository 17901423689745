import {useHistory} from 'react-router-dom';
import text_styles from 'shared/styles/text_styles.module.scss';
import Button from 'shared/components/Button.js';
import classNames from 'classnames';
import Icon from 'shared/components/Icon.js';
import {NOT_FOUND, RPC} from 'shared/api.js';
import {useState} from 'react';
import SwanLayout from 'shared/components/SwanLayout/SwanLayout.js';
import {redirectExternal} from 'shared/utils.js';
import LabeledCircleSpinner from 'shared/components/LabeledCircleSpinner.js';
import {OWNER_PATHS} from 'shared/constants.js';

import {handleError} from '../../effects.js';
import useVerifySwanOnboardingToken from '../../hooks/useVerifySwanOnboardingToken.js';

import {ReactComponent as CheckmarkCircle} from './checkmark-circle.svg';
import small from './getmomo-swan.png';
import medium from './getmomo-swan@2x.png';
import large from './getmomo-swan@3x.png';
import styles from './AccountOnboardedScreen.module.scss';

export default function AccountOnboardedScreen() {
  const [grant_membership_loading, setGrantMembershipLoading] = useState(false);
  const history = useHistory();

  const {data, error, loading, token, swan_user_auth_code} =
    useVerifySwanOnboardingToken();

  if (error) {
    if (error.code === NOT_FOUND) {
      history.replace(OWNER_PATHS.LinkInvalidScreen);
      return null;
    }
    return (
      <SwanLayout>
        <div className={styles.container}>
          <p className={classNames(text_styles.body2_centered, styles.text_1)}>
            Da ist wohl etwas schief gelaufen. Bitte kontaktieren Sie unseren
            Support.
          </p>
        </div>
      </SwanLayout>
    );
  }

  if (loading) {
    return (
      <SwanLayout>
        <div className={styles.container}>
          <p className={classNames(text_styles.body2_centered, styles.text_1)}>
            Bitte warten Sie einen Moment während wir Ihre Daten laden…
          </p>
        </div>
      </SwanLayout>
    );
  }

  return (
    <SwanLayout>
      <div className={styles.container}>
        <>
          {
            // eslint-disable-next-line @mizdra/layout-shift/require-size-attributes
            <img
              src={small}
              srcSet={`${small} 300w, ${medium} 768w, ${large} 1280w`}
              alt="momo swan"
              className={styles.img}
            />
          }
          <div className={styles.account_setup}>
            <Icon className={styles.icon}>
              <CheckmarkCircle className={styles.icon} />
            </Icon>
            <p
              className={classNames(
                text_styles.body1_bold_centered,
                styles.setup_text,
              )}>
              Konto-Einrichtung abgeschlossen
            </p>
          </div>
          <p
            className={classNames(
              text_styles.body1_bold_centered,
              styles.header,
            )}>
            Letzter Schritt:
          </p>
          <p className={classNames(text_styles.body2_centered, styles.text_1)}>
            Damit sich Ihre Hausverwaltung {data?.manager_display_name} für Sie
            um alle Prozesse kümmern kann, muss diese zur Verwaltung des Kontos
            berechtigt werden.
          </p>
          {grant_membership_loading ? (
            <LabeledCircleSpinner className={styles.please_wait} />
          ) : (
            <Button
              title="Hausverwaltung berechtigen"
              className={styles.button}
              disabled={grant_membership_loading}
              onClick={async () =>
                grantPropertyManagerBankingAccountMembership({
                  token,
                  swan_user_auth_code,
                  setLoading: setGrantMembershipLoading,
                  history,
                })
              }
            />
          )}
          <p className={classNames(text_styles.body2_centered, styles.text_2)}>
            Wir senden Ihnen anschließend eine Zusammenfassung zur Überpüfung
            der Daten
          </p>
        </>
      </div>
    </SwanLayout>
  );
}

async function grantPropertyManagerBankingAccountMembership({
  token,
  swan_user_auth_code,
  setLoading,
  history,
}) {
  setLoading(true);

  let add_manager_consent_url;

  try {
    add_manager_consent_url = await RPC(
      'grantPropertyManagerBankingAccountMembership',
      {
        token,
        swan_user_auth_code,
      },
    );
  } catch (err) {
    if (err.code === NOT_FOUND) {
      history.replace(OWNER_PATHS.LinkInvalidScreen);
      return;
    }
    handleError(err);
    setLoading(false);
    return;
  }

  redirectExternal(add_manager_consent_url);
}
