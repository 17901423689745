import {createStore, combineReducers} from 'redux';
import {devToolsEnhancer} from 'redux-devtools-extension';
import modalReducer from 'shared/store/modalReducer.js';

import initialState from './initialState.js';

const appReducer = combineReducers({
  modal: modalReducer,
});

function rootReducer(state, action) {
  if (action.type === 'RESET') {
    state = initialState();
  }
  return appReducer(state, action);
}

export default createStore(rootReducer, initialState(), devToolsEnhancer());
