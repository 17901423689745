import {Provider} from 'react-redux';
import ModalConnector from 'shared/components/modal/ModalConnector.js';

import store from './store/store.js';
import Router from './Router.js';

export default function App() {
  return (
    <Provider store={store}>
      <ModalConnector />
      <Router />
    </Provider>
  );
}
