import {BrowserRouter, Switch, Route} from 'react-router-dom';
import {OWNER_PATHS} from 'shared/constants.js';

import HomeScreen from './scenes/home/HomeScreen.js';
import AccountOnboardedScreen from './scenes/account-onboarded/AccountOnboardedScreen.js';
import ManagerAccountOnboardedScreen from './scenes/manager-account-onboarded/ManagerAccountOnboardedScreen.js';
import PreOnboardingScreen from './scenes/pre-onboarding/PreOnboardingScreen.js';
import AddManagerAccountMemberSuccessScreen from './scenes/add-manager-account-member-success/AddManagerAccountMemberSuccessScreen.js';
import LinkInvalidScreen from './scenes/link-invalid/LinkInvalidScreen.js';

export default function Router() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={`${OWNER_PATHS.PreOnboardingScreen}/:token?`}>
          <PreOnboardingScreen />
        </Route>
        <Route exact path={OWNER_PATHS.ManagerAccountOnboardedScreen}>
          <ManagerAccountOnboardedScreen />
        </Route>
        <Route exact path={OWNER_PATHS.AccountOnboardedScreen}>
          <AccountOnboardedScreen />
        </Route>
        <Route exact path={OWNER_PATHS.AddManagerAccountMemberSuccessScreen}>
          <AddManagerAccountMemberSuccessScreen />
        </Route>
        <Route exact path={OWNER_PATHS.LinkInvalidScreen}>
          <LinkInvalidScreen />
        </Route>
        <Route path="/">
          <HomeScreen />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}
