import {ReactComponent as MomoLogo} from '../../assets/momo_logo.svg';

import styles from './HomeScreen.module.scss';

export default function HomeScreen() {
  return (
    <div className={styles.container}>
      <MomoLogo />
    </div>
  );
}
